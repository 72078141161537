<template>
  <v-container>
    <v-card
        class="mx-auto"
        prepend-icon="mdi-alert-outline"
        title="No Game Today"
      >
        <v-card-text>There's no game today. Please come back on the day of a standalone game</v-card-text>
      </v-card>
      
  </v-container>
</template>

<script>
  export default {
      name: 'HomeNoGameSection'
  }
</script>