<template>
  <v-toolbar title="WELCOME"></v-toolbar>
  <!-- <v-row>
      <v-btn
        @click="true"
      >
        List Leagues
      </v-btn>
    </v-row>  -->
  <v-container
    v-if="dataLoaded"
  >
    <v-row
      v-for="dGame in dailyGames"
      :key="dGame.id"
    >
      <v-col
        cols="10"
      >
        <HomeGamePanel
          :game="dGame"
          :madePicks="dGame.madePicks"
          :pickTeam1Over="pickTeam1Over"
          :pickTeam2Over="pickTeam2Over"
          :pickTeam1Under="pickTeam1Under"
          :pickTeam2Under="pickTeam2Under"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="false"
    >
      <v-col
        cols="10"
      >
        <HomeLeaguesPanel
          
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
      >
        <HomeRecordPanel
          :record="userRecord"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeGamePanel from '../components/home/GamePanel.vue'
import HomeLeaguesPanel from '../components/home/LeaguesPanel.vue'
import HomeRecordPanel from '../components/home/RecordPanel.vue'
import { generateClient } from 'aws-amplify/data';
import { getCurrentUser } from 'aws-amplify/auth';
import { ref, onMounted } from 'vue'

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

export default {
  components: {
    HomeGamePanel,
    HomeLeaguesPanel,
    HomeRecordPanel,
  },

  props: {
    user: Object,
  },


//TODO:
//on sign up, prompt user for a name
//create league,
//search for users (by email)
//search for leagues
//add user to league
//'pick this game for all leagues that I am in' vs 'just for this league' maybe a checkbox next to the pick?????
//carousel at the top of picks page, a selectedLeague type thing similar to selectedRegion at work
//Leaderboard tab becomes leagues, 
  //landing page: view all leagues, create new league, search for leagues to join
  //subPage for each league: view leaderboard, your previous picks (current leaderboard page), if you are the league owner an add user section

  //API Changes to make:
  //leagues can have a password (otherwise they are public)
  //allow users to set a max members for a league
  //make display names forced unique (so you can find players) ?????
  //maybe MAYBE MAYBE user and league images/logos ?????

  setup() {
    const client = generateClient();
    const dataLoaded = ref(false);
    const madePicks = ref(false);
    const gameObj = ref(null);
    const userRecord = ref(null);
    const dailyGames = ref([])


    const createNorwichLeague = async () => {
      await client.models.League.create({
        id: "6d17651f-c165-44f1-9032-ad69f6162e56",
        leagueName: "132 Norwich",
      });
    }


    const checkForGameToday = async () => {
      //will need to update this to specifically look for today
      //also will need to update it to do a list of games??????? for days with many games
      //get both today and tomorrows games, but only keep tomorrow's games that start before 04
      //todaysDate
      let today = new Date().toISOString().substring(0,10)
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow = tomorrow.toISOString().substring(0,10)

      const todayRes = await await client.models.Game.listGameByStartDate(
        {
          startDate: today
        }
      )
      todayRes.data.forEach((element) => {
        if ((element.isStandalone || element.isPrimetime) && (!element.startTime.startsWith('00') || !element.startTime.startsWith('01') || !element.startTime.startsWith('02') || !element.startTime.startsWith('03'))) {
          if (new Date(element.startDateTime) >= new Date()){
            dailyGames.value.push(element)
          }
        }
      })

      const tomorrowRes = await client.models.Game.listGameByStartDate(
        {
          startDate: tomorrow
        }
      )
      tomorrowRes.data.forEach((element) => {
        if ((element.isStandalone || element.isPrimetime) && (element.startTime.startsWith('00') || element.startTime.startsWith('01') || element.startTime.startsWith('02') || element.startTime.startsWith('03'))) {
          if (new Date(element.startDateTime) >= new Date()){
            dailyGames.value.push(element)
          }
        }
      })
      
      gameObj.value = todayRes.data[0]
      //has the user made thier picks for this game??
      const activeUser = await getCurrentUser()
      
      const userRes = await client.models.User.get(
        {
          id: activeUser.userId,
        })
      const currentUser = userRes.data
      const userPicks = await currentUser.picks()
      userPicks.data.forEach((element) => {
        for (let i=0; i<dailyGames.value.length; i++) {
          if (dailyGames.value[i].id == element.gameId) {
            dailyGames.value[i].madePicks = true
          }
        }
      })
      const usrRecRes = await client.models.UserRecord.listUserRecordByUserId(
        {
          userId: activeUser.userId,
        }
      )
      userRecord.value = usrRecRes.data[0]
    }


    const addUserToLeague = async () => {
      const leagueRes = await client.models.League.listLeagueByLeagueName(
        {
          leagueName: "132 Norwich"
        });
      let myLeague;
      if (leagueRes.data.length == 0) {
        //create league
        const res = await client.models.League.create({
          leagueName: "132 Norwich",
        });
        myLeague = res.data
      } else {
        myLeague = leagueRes.data[0]
      }
      const userRes = await client.models.User.list();
      const myUser = userRes.data[0]
      await client.models.UserLeague.create({
        userId: myUser.id,
        leagueId: myLeague.id,
      });
    };
    const createMockUser = async () => {
      await client.models.User.create({
        email: "mail",
        name: "name"
      });
    }

    const createMockLeagueSeason = async () => {
      //get Norwich Season
      const leagueRes = await client.models.League.listLeagueByLeagueName(
        {
          leagueName: "132 Norwich"
        });
      const norwichLeague = leagueRes.data[0]
      const res = await client.models.LeagueSeason.create({
        seasonStartYear: "2024",
        leagueId: norwichLeague.id
      });
      const currentUser = await getCurrentUser()
      await client.models.UserRecord.create({
        leagueSeasonId: res.data.id,
        userId: currentUser.userId,
        overallWins: 10,
        overallLosses: 4,
        spreadWins: 6,
        spreadLosses: 1,
        pointTotalWins: 4,
        pointTotalLosses: 3,
      });
    }
    const create2024nflSeason = async () => {
      const seasonRes = await client.models.Season.create({
        seasonStartYear: "2024",
      });
      const weekRes = await client.models.Week.create({
        seasonId: seasonRes.data.id,
      })
      await client.models.Game.create({
        weekId: weekRes.data.id,
        team1: "Green Bay Packers",
        team2: "Detroit Lions",
        spread: "-2.5",
        total: "46.5",
        startDateTime: new Date().toISOString(),
        startDate: new Date().toISOString().substring(0, 10),
        startTime: new Date().toISOString().substring(11),
        isStandalone: true,
        isHoliday: false,
        isPrimetime: true,
        isMorning: false,
      })

    }
    const fetchUsers = async () => {
      await client.models.User.list();
    };
    const fetchLeagues = async () => {
      await client.models.League.list();
    };
    const fetchUsersOfLeague = async () => {
      const leagues = await client.models.League.list();
      await leagues.data[0].users()
    }
    async function logUser(){
      const currentUser = await getCurrentUser()
      const res = await client.models.User.get(
        {
          id: currentUser.userId,
        },
        {
          authMode: 'userPool',
        });
      if (res.data === null) {
        console.log('user does not exist, creating user')
        await client.models.User.create({
          email: currentUser.signInDetails.loginId,
          id: currentUser.userId,
        });
        //add user to  132 norwich if they arent already in it
        const leagueRes = await client.models.League.listLeagueByLeagueName(
        {
          leagueName: "132 Norwich"
        });
        let norwichLeague;
        if (leagueRes.data.length == 0) {
          //create league
          const res = await client.models.League.create({
            leagueName: "132 Norwich",
          });
          norwichLeague = res.data
        } else {
          norwichLeague = leagueRes.data[0]
        }
        const norwichMembers = await norwichLeague.users()
        let userInLeague = false
        norwichMembers.data.forEach((element) => {
          if (currentUser.userId == element.userId) {
            userInLeague = true
          }
        })
        if (userInLeague) {
          console.log("user is in norwich league already")
        } else {
          console.log('user not in norwich league yet')
          await client.models.UserLeague.create({
            userId: currentUser.userId,
            leagueId: norwichLeague.id,
          });
          console.log('added user to norwich league hopefully')
          //create a userRecord with them
          await client.models.UserRecord.create({
            leagueSeasonId: "5dc399c0-3430-4347-8799-ca5dc3172435",
            userId: currentUser.userId,
            overallWins: 0,
            overallLosses: 0,
            spreadWins: 0,
            spreadLosses: 0,
            pointTotalWins: 0,
            pointTotalLosses: 0,
          });
          
        }
      } else {
        console.log('USER FOUND')
      }
    }

    const pickTeam1Over = async (gameId) => {
      //get game and user
      
      const activeUser = await getCurrentUser()
      const userRes = await client.models.User.get(
        {
          id: activeUser.userId,
        })
      const currentUser = userRes.data
      const userPicks = await currentUser.picks()
      let userPickedAlready = false
      userPicks.data.forEach((element) => {
        if (element.gameId == gameId) {
          userPickedAlready = true
        }
      })
      if (!userPickedAlready) {
        const game = await client.models.Game.get(
      {
        id: gameId,
      })
      //get league (norwich)
      const leagueRes = await client.models.League.listLeagueByLeagueName(
      {
        leagueName: "132 Norwich"
      });
      const norwichLeague = leagueRes.data[0]
      await client.models.Pick.create({
        gameId: gameId,
        userId: activeUser.userId,
        leagueId: norwichLeague.id,
        spreadSelection: game.data.team1,
        totalSelection: "over"
      })
      for (let i=0; i<dailyGames.value.length; i++) {
        if (dailyGames.value[i].id == gameId) {
          dailyGames.value[i].madePicks = true
        }
      }
      madePicks.value=true
      } else {
        console.log('USER PICKED ALREADY')
      }
    }
    const pickTeam2Over = async (gameId) => {
      //get game and user
      
      const activeUser = await getCurrentUser()
      const userRes = await client.models.User.get(
        {
          id: activeUser.userId,
        })
      const currentUser = userRes.data
      const userPicks = await currentUser.picks()
      let userPickedAlready = false
      userPicks.data.forEach((element) => {
        if (element.gameId == gameId) {
          userPickedAlready = true
        }
      })
      if (!userPickedAlready) {
        const game = await client.models.Game.get(
      {
        id: gameId,
      })
      //get league (norwich)
      const leagueRes = await client.models.League.listLeagueByLeagueName(
      {
        leagueName: "132 Norwich"
      });
      const norwichLeague = leagueRes.data[0]
      await client.models.Pick.create({
        gameId: gameId,
        userId: activeUser.userId,
        leagueId: norwichLeague.id,
        spreadSelection: game.data.team2,
        totalSelection: "over"
      })
      for (let i=0; i<dailyGames.value.length; i++) {
        if (dailyGames.value[i].id == gameId) {
          dailyGames.value[i].madePicks = true
        }
      }
      madePicks.value=true
      } else {
        console.log('USER PICKED ALREADY')
      }
    }
    const pickTeam1Under = async (gameId) => {
      //get game and user
      
      const activeUser = await getCurrentUser()
      const userRes = await client.models.User.get(
        {
          id: activeUser.userId,
        })
      const currentUser = userRes.data
      const userPicks = await currentUser.picks()
      let userPickedAlready = false
      userPicks.data.forEach((element) => {
        if (element.gameId == gameId) {
          userPickedAlready = true
        }
      })
      if (!userPickedAlready) {
        const game = await client.models.Game.get(
      {
        id: gameId,
      })
      //get league (norwich)
      const leagueRes = await client.models.League.listLeagueByLeagueName(
      {
        leagueName: "132 Norwich"
      });
      const norwichLeague = leagueRes.data[0]
      await client.models.Pick.create({
        gameId: gameId,
        userId: activeUser.userId,
        leagueId: norwichLeague.id,
        spreadSelection: game.data.team1,
        totalSelection: "under"
      })
      for (let i=0; i<dailyGames.value.length; i++) {
        if (dailyGames.value[i].id == gameId) {
          dailyGames.value[i].madePicks = true
        }
      }
      madePicks.value=true
      } else {
        console.log('USER PICKED ALREADY')
      }
    }
    const pickTeam2Under = async (gameId) => {
      //get game and user
      
      const activeUser = await getCurrentUser()
      const userRes = await client.models.User.get(
        {
          id: activeUser.userId,
        })
      const currentUser = userRes.data
      const userPicks = await currentUser.picks()
      let userPickedAlready = false
      userPicks.data.forEach((element) => {
        if (element.gameId == gameId) {
          userPickedAlready = true
        }
      })
      if (!userPickedAlready) {
        const game = await client.models.Game.get(
      {
        id: gameId,
      })
      //get league (norwich)
      const leagueRes = await client.models.League.listLeagueByLeagueName(
      {
        leagueName: "132 Norwich"
      });
      const norwichLeague = leagueRes.data[0]
      await client.models.Pick.create({
        gameId: gameId,
        userId: activeUser.userId,
        leagueId: norwichLeague.id,
        spreadSelection: game.data.team2,
        totalSelection: "under"
      })
      for (let i=0; i<dailyGames.value.length; i++) {
        if (dailyGames.value[i].id == gameId) {
          dailyGames.value[i].madePicks = true
        }
      }
      madePicks.value=true
      } else {
        console.log('USER PICKED ALREADY')
      }
      
    }
      /////FUNCTIONS ABOVE, CODE BELOW ?????????????
      checkForGameToday()

      dataLoaded.value = true

    onMounted(() => {
      logUser()
    })
    return {
      fetchUsers,
      pickTeam1Over,
      checkForGameToday,
      pickTeam2Over,
      pickTeam1Under,
      pickTeam2Under,
      fetchLeagues,
      createNorwichLeague,
      logUser,
      createMockUser,
      createMockLeagueSeason,
      dailyGames,
      dataLoaded,
      userRecord,
      madePicks,
      gameObj,
      create2024nflSeason,
      fetchUsersOfLeague,
      addUserToLeague,
    }
  },

  methods: {
    goToHome() {
        this.$router.push('/')
      },
      goToLeague() {
        this.$router.push('/league')
      },
      goToLeaderboards() {
        this.$router.push('/leaderboards')
      },
      goToSettings() {
        this.$router.push('/settings')
      },
      goToAbout() {
        this.$router.push('/about')
      },
  },
}
</script>
