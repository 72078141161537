<template>
  <v-container>
    <v-card
        class="mx-auto"
        prepend-icon="mdi-alert-outline"
        title="It's Game Day!"
      >
        <v-card-text>Here's the next game. Please click this box or navigate to the Picks Page to make your picks</v-card-text>
        <v-card-text>
          <v-row
          class="homeTeamInfo"
          >
            <v-col
              class="logoContainer gameCol"
              cols="2"
            >
              <v-img
                :src="require('@/assets/football.svg')"
              />
            </v-col>
            <v-col
              cols="3"
              class="teamName gameCol"
            >
              {{ game.team1 }}
              
            </v-col>
            <v-col
              cols="2"
              class="spreadAway gameCol"
            >
              {{  game.spread }}
            </v-col>
            <v-col
              cols="2"
              class="totalOver gameCol"
            >
              Over {{ game.total }}
            </v-col>
            <v-col
              cols="3"
              class="selectionOptions gameCol"
              v-if="!madePicks"
            >
              <v-row
                class="btnRow"
              >
                <v-btn
                  class="selectButton"
                  @click="$emit('team1OverPick', game.id)"
                >
                  {{ game.team1 }} OVER
                </v-btn>
              </v-row>
              <v-row
                class="btnRow"
              >
                <v-btn
                  class="selectButton"
                  @click="$emit('team1UnderPick', game.id)"
                >
                {{ game.team1 }} UNDER
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="selectionOptions gameCol"
              v-else
            >
              You've Made your selections for this game
            </v-col>
          </v-row>
          <v-row
          >
            <v-col
              cols="2"
              class="logoContainer gameCol"
            >
              <v-img
                :src="require('@/assets/football.svg')"
              />
            </v-col>
            <v-col
              cols="3"
              class="teamName gameCol"
            >
              {{ game.team2 }}
            </v-col>
            <v-col
              cols="2"
              class="spreadHome gameCol"
            >
              {{ (0 - parseFloat(game.spread)).toString() }}
            </v-col>
            <v-col
              cols="2"
              class="totalUnder gameCol"
            >
              Under {{ game.total }}
            </v-col>
            <v-col
              cols="3"
              class="selectionOptions gameCol"
              v-if="!madePicks"
            >
              <v-row
                class="btnRow"
              >
                <v-btn
                  class="selectButton"
                  @click="$emit('team2OverPick', game.id)"
                >
                  {{ game.team2 }} OVER
                </v-btn>
              </v-row>
              <br>
              <v-row
                class="btnRow"
              >
                <v-btn
                  class="selectButton"
                  @click="$emit('team2UnderPick', game.id)"
                >
                {{ game.team2 }} UNDER
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="selectionOptions gameCol"
              v-else
            >
              You've Made your selections for this game
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
  
  export default {
    name: 'HomeTeamsSection',

    props: {
      game: Object,
      madePicks: Boolean,
    },

    data () {
      return {
        bettingData: [
          {
            teams: [
              {
                name: 'Detroit Lions',
                logo: '@/assets/Lions.svg',
              },
              {
                name:'Fudge Packers',
                logo:'@/assets/Packers.svg',
              },
            ],
          },
        ],
      }
    },
  }
</script>
<style>
.logoContainer {
  border-right: 1px solid black;
}
.teamName {
  border-right: 1px solid black;
}
.spreadHome {
  border-right: 1px solid black;
}
.totalOver {
  border-right: 1px solid black;
}
.totalUnder {
  border-right: 1px solid black;
}
.spreadAway {
  border-right: 1px solid black;
}
.homeTeamInfo {
  border-bottom: 1px solid black;
}
.selectButton {
  font-size: 0.8vw;
}
</style>