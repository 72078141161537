<template>
  <v-toolbar title="LEAGUE"></v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card
            title="Standings"
            flat
          >
            <v-data-table
              :headers="standingsHeaders"
              :items="members"
              v-model:sort-by="sortBy"
            >
              <template #bottom></template>
            </v-data-table>
          </v-card>
        </v-col>
        <div class="vl"></div>
        <v-col cols="5">
          <v-card
            title="Picks History"
            flat
          >
            <v-data-table
              :headers="picksHeaders"
              :items="picks"
            >
              <template #bottom></template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { generateClient } from 'aws-amplify/data';
  import { getCurrentUser } from 'aws-amplify/auth';
  import { ref } from 'vue'

  /**
   * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
   */

  
  export default {
    name: 'LeagueView',

    async setup() {
      //DATA
      const client = generateClient();
      const leagueID = ref('');
      const sortBy = ref([{ key: 'overallRecord', order: 'desc' }])
      const standingsHeaders = ref([
        {
          align: 'start',
          key: 'name',
          sortable: false,
          title: 'Name',
        },
        { key: 'overallRecord', title: 'Overall Record' },
        { key: 'spreadRecord', title: 'Record Against The Spread' },
        { key: 'pointTotalRecord', title: 'Record Against the O/U' },
      ])
      const picksHeaders = ref(
        [
          {
            align: 'start',
            key: 'date',
            title: 'Date of Game',
          },
          { key: 'spreadPick', title: 'Spread Pick', sortable: false },
          { key: 'opponent', title: 'Opponent', sortable: false },
          { key: 'pointTotalPick', title: 'O/U Pick', sortable: false },
        ]
      )
      const activeUser = ref(null)
      const picks = ref([])
      const members = ref([])

      //FUNCTIONS
      const getActiveUser = async() => {
        activeUser.value = await getCurrentUser()
        console.log(activeUser.value.userId)
      }
      const getLeagueInfo = async() => {
        const leagueRes = await client.models.League.listLeagueByLeagueName(
          {
            leagueName: "132 Norwich"
          });
        let myLeague;
        if (leagueRes.data.length == 0) {
          //create league
          const res = await client.models.League.create({
            leagueName: "132 Norwich",
          });
          myLeague = res.data
        } else {
          myLeague = leagueRes.data[0]
        }
        leagueID.value = myLeague.id
        //get Current Leage season
        const leagueSeasons = await myLeague.leagueSeasons()
        const currentLeagueSeason = leagueSeasons.data[0]
        //get list of user records in that league season
        const recordsList = await client.models.UserRecord.listUserRecordByLeagueSeasonId({
          leagueSeasonId: currentLeagueSeason.id
        })
        //get league members
        const leagueMembers = await myLeague.users()
        leagueMembers.data.forEach(async(element) => {
          //get user object
          const mem = await client.models.User.get({
            id: element.userId,
          });
          //get users record in current league season

          let userRec;
          recordsList.data.forEach((element) => {
            if (element.userId === mem.data.id) {
              userRec = element
            }
          })
          members.value.push({
            name: mem.data.name,
            overallRecord: userRec.overallWins + "-" + userRec.overallLosses,
            spreadRecord: userRec.spreadWins + "-" + userRec.spreadLosses,
            pointTotalRecord: userRec.pointTotalWins + "-" + userRec.pointTotalLosses,
          })
        })
      }
      const getPicksHistory = async() => {
        const usrPicks = []
        const picksRes = await client.models.Pick.listPickByUserId({
          userId: activeUser.value.userId,
        })
        picksRes.data.forEach((e) => {
          if (e.leagueId === leagueID.value) {
            usrPicks.push(e)
          }
        })
        usrPicks.forEach(async(e) => {
          const associatedGame = await client.models.Game.get({
            id: e.gameId
          })
          let pickedTeam, opponent;
          if (e.spreadSelection === associatedGame.data.team1){
            pickedTeam = e.spreadSelection
            opponent = associatedGame.data.team2
          } else {
            pickedTeam = e.spreadSelection
            opponent = associatedGame.data.team1
          }
          picks.value.push({
            date: associatedGame.data.startDate,
            spreadPick: pickedTeam,
            opponent: opponent,
            pointTotalPick: e.totalSelection
          })
        })
        //get picks for the user
      }


      //CODE
      await getActiveUser()
      await getLeagueInfo()
      await getPicksHistory()

      return {
        sortBy,
        standingsHeaders,
        picksHeaders,
        picks,
        members,
        activeUser
      }
    },

    props: {
      user: Object,
    },
  
    methods: {
      goToHome() {
        this.$router.push('/')
      },
    },
   }
  </script>
  
<style>
.vl {
  border-left: 1px solid black;
  height: 500px;
}
</style>