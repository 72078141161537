<template>
  <v-container>
    <v-card>
      <v-card-title  
        elevation-11
        primary-title
      >
        Make Your Picks!
      </v-card-title>
      <v-card-text>
        <HomeTeamsSection
          v-if="game != null"
          @team1OverPick="pickTeam1Over"
          @team2OverPick="pickTeam2Over"
          @team1UnderPick="pickTeam1Under"
          @team2UnderPick="pickTeam2Under"
          :game="game"
          :madePicks="madePicks"
        />
        <HomeNoGameSection
          v-else
        />
      </v-card-text>
  </v-card>
      
  </v-container>
</template>

  
  <script>
  import HomeTeamsSection from './TeamsSection.vue'
  import HomeNoGameSection from './NoGameSection.vue'
  export default {
      name: 'HomeGamePanel',

      components: {
        HomeTeamsSection,
        HomeNoGameSection,
      },

      props: {
        game: Object,
        madePicks: Boolean,
        pickTeam1Over: Function,
        pickTeam2Over: Function,
        pickTeam1Under: Function,
        pickTeam2Under: Function,
      },
    
      methods: {
        goToHome() {
          this.$router.push('/')
        },
        goToLeague() {
          this.$router.push('/league')
        },
        goToLeaderboards() {
          this.$router.push('/leaderboards')
        },
        goToSettings() {
          this.$router.push('/settings')
        },
        goToAbout() {
          this.$router.push('/about')
        },
      },
    
  }
    </script>
  
  <style>
  
  .mainLogo {
      height: 30px;
      width: 30px;
      padding-right: 10px;
  }
  </style>