<template>
  <v-container>
    <v-card class ="leagueCard">
      <v-card-title  
        elevation-11
        primary-title
      >
        View Your Leagues
      </v-card-title>
      <v-row>
        <v-col cols="2">
          <v-card
            class="leagueLogoCard"
          >
            <v-img
              class="leagueLogo"
              :src="require('@/assets/shocker.svg')"
            ></v-img>

            <v-card-title
              class="leagueName"
            >
              Homie Betting
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card
            class="leagueLogoCard"
          >
            <v-img
              class="leagueLogo"
              :src="require('@/assets/shocker.svg')"
            ></v-img>

            <v-card-title
              class="leagueName"
            >
              League 2
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card
            class="leagueLogoCard"
          >
            <v-img
              class="leagueLogo"
              :src="require('@/assets/shocker.svg')"
            ></v-img>

            <v-card-title
              class="leagueName"
            >
              League 3
            </v-card-title>
          </v-card>
        </v-col>
    </v-row>
  </v-card>
      
  </v-container>
  
</template>

  
  <script>
  export default {
      name: 'HomeLeaguesPanel',

      components: {
      },
    
      methods: {
        goToHome() {
          this.$router.push('/')
        },
        goToLeague() {
          this.$router.push('/league')
        },
        goToLeaderboards() {
          this.$router.push('/leaderboards')
        },
        goToSettings() {
          this.$router.push('/settings')
        },
        goToAbout() {
          this.$router.push('/about')
        },
      },
    
  }
    </script>
  
  <style>
  
  .mainLogo {
      height: 30px;
      width: 30px;
      padding-right: 10px;
  }
  
  .leagueLogo {
    max-width: 10vw;
    max-height: 10vw;
  }
  .leagueLogoCard {
    max-width: 10vw;
    max-height: 13vw;
  }
  .leagueName {
    font-size: 1vw
  }
  .leagueCard {
    padding: 10px;
  }
  </style>