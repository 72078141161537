<template>
  <v-app>
    <authenticator>
      <template v-slot="{ user }">
        <v-main>
          <NavbarSide 
            @log-out="logout"
            :username="user.signInDetails.loginId"
          />
          <RouterView
            :user="user"
          />
        </v-main>
      </template>
    </authenticator>
    
  </v-app>
</template>

<script>
import NavbarSide from './components/navbar/Navbar.vue'
import { useTheme } from 'vuetify'
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { Amplify } from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';
import outputs from '../amplify_outputs.json';

export default {
  name: 'App',

  components: {
    NavbarSide,
    Authenticator
  },
  setup() {
    Amplify.configure(outputs);
    const theme = useTheme();
    function toggleTheme() {
      console.log(theme)
      theme.global.name.value = theme.global.current.value ? 'light' : 'dark'
    }
    async function logout(){
      await signOut({ global: true });
    }
    return {
      theme,
      toggleTheme,
      logout,
    }
  },

  data: () => ({
  }),
}
</script>
