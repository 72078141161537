<template>
    <v-toolbar title="ABOUT"></v-toolbar>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="require('../assets/football.svg')"
            class="my-3"
            contain
            height="200"
          />
          <p>
            Who's better at betting on football this year?
          </p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  
  export default {
    name: 'AboutView',
  
    methods: {
      goToHome() {
        this.$router.push('/')
      },
    },
  
    data: () => ({
    }),
  }
  </script>
  