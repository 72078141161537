<template>
    <v-navigation-drawer
        expand-on-hover
        rail
    >
        <v-list>
            <v-list-item
            :subtitle="currentUser.name"
            :title="username"
            >
                <template v-slot:prepend>
                    <img :src="require('@/assets/football.svg')" class="mainLogo"/>
                </template>
                
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list  nav>
            <v-list-item link prepend-icon="mdi-home" title="Home" @click="goToHome"></v-list-item>
            <v-list-item link prepend-icon="mdi-account-group" title="League" @click="goToLeague"></v-list-item>
            <!--<v-list-item link prepend-icon="mdi-format-list-numbered" title="Leaderboards" @click="goToLeaderboards"></v-list-item>-->
            <v-list-item link prepend-icon="mdi-cog" title="Settings" @click="goToSettings"></v-list-item>
            <v-list-item link prepend-icon="mdi-information-outline" title="About" @click="goToAbout"></v-list-item>
            <v-list-item link prepend-icon="mdi-logout" title="Sign Out" @click="$emit('log-out')"></v-list-item>
        </v-list>
    </v-navigation-drawer>
  </template>


<script>

import { getCurrentUser } from 'aws-amplify/auth';

export default {
    name: 'NavbarSide',

    async setup() {
      const currentUser = await getCurrentUser()
      return {
        currentUser
      }
    },

    props: {
      username: String,
    },
  
    methods: {
      goToHome() {
        this.$router.push('/')
      },
      goToLeague() {
        this.$router.push('/league')
      },
      goToLeaderboards() {
        this.$router.push('/leaderboards')
      },
      goToSettings() {
        this.$router.push('/settings')
      },
      goToAbout() {
        this.$router.push('/about')
      },
    },
  
}
  </script>

<style>

.mainLogo {
    height: 30px;
    width: 30px;
    padding-right: 10px;
}
</style>