import { createMemoryHistory, createRouter } from 'vue-router'

import HomeView from './views/HomeView.vue'
import AboutView from './views/AboutView.vue'
import LeagueView from './views/LeagueView.vue'
import LeaderboardView from './views/LeaderboardView.vue'
import SettingsView from './views/SettingsView.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/about', component: AboutView },
  { path: '/league', component: LeagueView },
  { path: '/leaderboards', component: LeaderboardView },
  { path: '/settings', component: SettingsView },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

export default router