<template>
  <v-container>
    <v-card>
      <v-card-title  
        elevation-11
        primary-title
      >
        Your Record on the Season
      </v-card-title>
      <br>
      <v-card-text
        v-if="record != null"
      >
        <v-row>
          <v-col cols="4" class="textHolder center">
            <h1 class="titleText">
              Overall
            </h1>
          </v-col>
          <v-col cols="4" class="textHolder center">
            <h1 class="titleText">
              Against The Spread
            </h1>
          </v-col>
          <v-col cols="4" class="textHolder center">
            <h1 class="titleText">
              Over/Under
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="center">
            <p class="recordText">
              {{ record.overallWins }}-{{ record.overallLosses }}
            </p>
          </v-col>
          <v-col cols="4" class="center">
            <p class="recordText">
              {{ record.spreadWins }}-{{ record.spreadLosses }}
            </p>
          </v-col>
          <v-col cols="4" class="center">
            <p class="recordText">
              {{ record.pointTotalWins }}-{{ record.pointTotalLosses }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
  </v-card>
  </v-container>
</template>

  
  <script>
  export default {
      name: 'HomeRecordPanel',

      components: {
      },

      props: {
        record: Object,
      },
    
      methods: {
        goToHome() {
          this.$router.push('/')
        },
        goToLeague() {
          this.$router.push('/league')
        },
        goToLeaderboards() {
          this.$router.push('/leaderboards')
        },
        goToSettings() {
          this.$router.push('/settings')
        },
        goToAbout() {
          this.$router.push('/about')
        },
      },
    
  }
    </script>
  
  <style>
  .textHolder {
    border-bottom: 1px solid black;
  }
  .center {
    justify-content: center;
    display: flex;
  }
  .recordText {
    font-weight: bold;
    font-size: 3vw;
  }
  </style>