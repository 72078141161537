<template>
  <v-toolbar title="SETTINGS"></v-toolbar>
    <v-container >
      <v-row class="test">
        <v-col cols="12">
          <v-btn
            @click="toggleTheme"
          >
            Toggle Theme
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="showNameField"
      >
        <v-col
        cols="3"
        >
          <v-text-field
            v-model="name"
            label="Update Name"
            :placeholder="placeholder"
            type="email"
          ></v-text-field>
        </v-col>
        <v-col
          cols="3"
          >
            <v-btn
              @click="updateName(currentUser)"
            >
              Update Name
            </v-btn>
          </v-col>
      </v-row>
      <v-row
        v-if="nameUpdated"
      >
        <p>
          SUCCESSFULLY UPDATED DISPLAY NAME
        </p>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { useTheme } from 'vuetify'
  import { getCurrentUser } from 'aws-amplify/auth';
  import { generateClient } from 'aws-amplify/data';
  /**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
  
  
  export default {
    name: 'SettingsView',

    props: {
      user: Object,
    },

    async setup() {
      const client = generateClient();
      let currentUser;
      let showNameField = false;
      let placeholder = "";
      const theme = useTheme()
      function toggleTheme () {
        theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
      }
      currentUser = await getCurrentUser();
        const res = await client.models.User.get(
        {
          id: currentUser.userId,
        },
        {
          authMode: 'userPool',
        });
        if (res.data != null) {
          currentUser = res.data
          placeholder = currentUser.name
          showNameField = true
      }
      return {
        theme,
        currentUser,
        showNameField,
        placeholder,
        toggleTheme
      }
    },

    data () {
      return {
        name: "",
        client: generateClient(),
        nameUpdated: false
      }
    },
  
    methods: {
      goToHome() {
        this.$router.push('/')
      },
      async updateName(currentUser) {
        if (this.name != "") {
          console.log(this.name)
          console.log(currentUser);
          const updatedUserInfo = {
            id: currentUser.id,
            name: this.name,
          };
          await this.client.models.User.update(updatedUserInfo,
          {
            authMode: 'userPool',
          }
          );
          this.nameUpdated = true;
        } else{
          console.log('empty name')
        }
      }
    },
  }
  </script>

<style>
	.test {
    background: var(--color-background-secondary)
  }
</style>
  